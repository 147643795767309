<div class="cluster-card">
  <div class="cluster-card__header">
    @if (cluster.name | isNotEmpty) {
    <h2>{{ cluster.name }}</h2>
    } @else {
    <h2>{{ cluster.clusterId }}</h2>
    }

    <app-options-button>
      <app-popover-item
        (click)="openEditNameDialog()"
        [label]="'cluster.editName' | translate"
        icon="edit"
      ></app-popover-item>

      <app-popover-item
        (click)="openDeleteClusterDialog()"
        [label]="'cluster.delete' | translate"
        icon="delete"
      ></app-popover-item>
    </app-options-button>
  </div>

  <div class="cluster-card__body">
    <div class="cluster-card__column">
      <div class="cluster-card__row">
        <p translate>cluster.id</p>
        <p>{{ cluster.clusterId }}</p>
      </div>

      <div class="cluster-card__row">
        <p translate>cluster.domain</p>
        <p>{{ cluster.domain }}</p>
      </div>
    </div>

    <div class="cluster-card__column">
      <div class="cluster-card__row">
        <p translate>cluster.version</p>
        <p>{{ cluster.version }}</p>
      </div>

      <div class="cluster-card__row">
        <p translate>cluster.secret</p>

        <div class="cluster-card__secret">
          <p>{{ maskedSecret }}</p>

          <app-copy-tooltip [value]="cluster.clusterSecret">
            <mat-icon fontSet="material-icons-outlined">content_copy</mat-icon>
          </app-copy-tooltip>
        </div>
      </div>
    </div>

    <div class="cluster-card__column">
      <div class="cluster-card__row">
        <p translate>cluster.created</p>
        <p>{{ cluster.createdAt | timestamp }}</p>
      </div>

      <div class="cluster-card__row">
        <p translate>cluster.updated</p>
        <p>{{ cluster.updatedAt | timestamp }}</p>
      </div>
    </div>
  </div>
</div>
