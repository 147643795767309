<div class="snackbar">
  <div class="snackbar__wrapper">
    @if (data.icon) {
    <mat-icon class="{{ data.icon }}">{{ data.icon }}</mat-icon>
    } @if (data.message) {
    <p>{{ data.message }}</p>
    }
  </div>

  <button mat-button (click)="dismiss()">
    {{ "common.close" | translate }}
  </button>
</div>
