<h1 translate>installation.installation</h1>

<div class="installation__cards">
  <app-installation-card [title]="'installation.documentation' | translate">
    <p>{{ "installation.installationProcess" | translate }}</p>
    <ul class="installation__doc-links">
      <li>
        <a [href]="DocumentationURL.EKS" target="_blank">{{
          "installation.eks" | translate
        }}</a>
      </li>
      <li>
        <a [href]="DocumentationURL.AKS" target="_blank">{{
          "installation.aks" | translate
        }}</a>
      </li>
      <li>
        <a [href]="DocumentationURL.GKE" target="_blank">{{
          "installation.gke" | translate
        }}</a>
      </li>
    </ul>
  </app-installation-card>

  <app-installation-card [title]="'installation.licenseKey' | translate">
    <p translate>
      {{ "installation.copyContent" | translate }}
      <a [href]="ExternalURL.ARTIFACT_HUB" target="_blank" translate
        >installation.helmChart</a
      >
      {{ "installation.toUseLicenseKey" | translate }}
    </p>
    <app-code
      [data]="licenseValues"
      [copyable]="true"
      [title]="'installation.licenseValues' | translate"
    ></app-code>
  </app-installation-card>

  <app-installation-card [title]="'installation.dockerAccessToken' | translate">
    <p translate>
      {{ "installation.copyContent" | translate }}
      <a [href]="ExternalURL.ARTIFACT_HUB" target="_blank" translate
        >installation.helmChart</a
      >
      {{ "installation.toPullImages" | translate }}
    </p>
    <app-code
      [data]="imageValues"
      [copyable]="true"
      [title]="'installation.imageValues' | translate"
    ></app-code>
  </app-installation-card>

  <app-installation-card [title]="'installation.status' | translate">
    <p>
      {{ "installation.ifEnabledStatusPage" | translate }}
      <b>{{ "installation.installationStatusUrl" | translate }}</b>
    </p>
    <app-code
      [data]="statusValues"
      [copyable]="true"
      [title]="'installation.statusValues' | translate"
    ></app-code>
  </app-installation-card>
</div>
