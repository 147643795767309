<div
  (mouseenter)="tooltip.show()"
  (mouseleave)="tooltip.hide()"
  [floatUi]="tooltip"
  [disabled]="true"
  [placement]="placement"
>
  <ng-content></ng-content>
</div>

<div class="tooltip">
  <float-ui-content #tooltip>
    {{ label | translate }}
  </float-ui-content>
</div>
