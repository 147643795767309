import { Component, Input, SimpleChanges } from '@angular/core';

import { AvatarColor } from '../../../enums/avatar-color';
import { hasChanges } from '../../../functions/has-changes';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { LETTER_COLORS } from '../../../constants/letter-colors';

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [SharedModule, MaterialModule],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
})
export class AvatarComponent {
  public LETTER_COLORS = LETTER_COLORS;

  @Input() letter: string;

  public ngOnChanges({ letter }: SimpleChanges): void {
    const letterChanged = hasChanges(letter);

    if (letterChanged) {
      this.letter = this.letter.toUpperCase();
    }
  }
}
