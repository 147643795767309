import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  constructor(public functions: Functions) {}

  public async call<T>(path: string, data?: { [key: string]: any }): Promise<T> {
    return httpsCallable(this.functions, path)(data).then((res) => res.data as T);
  }
}
