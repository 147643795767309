import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IConfirmDeletionDialogData } from '../../../interfaces/confirm-deletion-dialog-data.interface';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { SecondaryButtonComponent } from '../buttons/secondary-button/secondary-button.component';
import { PrimaryButtonComponent } from '../buttons/primary-button/primary-button.component';

@Component({
  selector: 'app-confirm-deletion-dialog',
  standalone: true,
  imports: [SharedModule, MaterialModule, SecondaryButtonComponent, PrimaryButtonComponent],
  templateUrl: './confirm-deletion-dialog.component.html',
  styleUrl: './confirm-deletion-dialog.component.scss',
})
export class ConfirmDeletionDialogComponent {
  public deleteForm: UntypedFormGroup;
  public title: string;
  public description: string;
  public confirmLabel: string;

  private readonly CONFIRM_BY_TYPING_DEFAULT = 'delete';

  public get confirmByTyping(): AbstractControl {
    return this.deleteForm.get('confirmByTyping');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: IConfirmDeletionDialogData,
    private dialogRef: MatDialogRef<ConfirmDeletionDialogComponent>,
    private fb: UntypedFormBuilder,
  ) {
    this.title = this.data.title;
    this.description = this.data.description;
    this.confirmLabel = this.data.confirmByTyping ?? this.CONFIRM_BY_TYPING_DEFAULT;
  }

  public ngOnInit(): void {
    this.createDeleteForm();
  }

  public createDeleteForm(): void {
    this.deleteForm = this.fb.group({
      confirmByTyping: ['', [Validators.required]],
    });
  }

  public confirm(): void {
    if (this.confirmLabel !== this.confirmByTyping.value) return;
    this.dialogRef.close(true);
  }

  public close(confirmed?: boolean): void {
    this.dialogRef.close(confirmed);
  }
}
