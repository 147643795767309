@if (clusters && clusters.length > 0) {
<div class="dashboard">
  <h1 translate>dashboard.dashboard</h1>
  <p translate>dashboard.info</p>

  <div class="dashboard__entities">
    <app-entity-card
      [count]="statisticsSumByField('workspacesCount')"
      [label]="'dashboard.entity.workspaces' | translate"
    ></app-entity-card>
    <app-entity-card
      [count]="statisticsSumByField('usersCount')"
      [label]="'dashboard.entity.users' | translate"
    ></app-entity-card>
    <app-entity-card
      [count]="statisticsSumByField('repositoriesCount')"
      [label]="'dashboard.entity.repositories' | translate"
    ></app-entity-card>
    <app-entity-card
      [count]="statisticsSumByField('deploymentsCount')"
      [label]="'dashboard.entity.deployments' | translate"
    ></app-entity-card>
    <app-entity-card
      [count]="statisticsSumByField('tokensCount')"
      [label]="'dashboard.entity.tokens' | translate"
    ></app-entity-card>
  </div>

  <h2 translate>dashboard.clusters</h2>

  <div
    [ngClass]="{ 'dashboard__clusters--two-columns': clusters.length > 1 }"
    class="dashboard__clusters"
  >
    @for (cluster of clusters; track cluster.clusterId) {
    <app-cluster-card [cluster]="cluster"></app-cluster-card>
    }
  </div>
</div>
} @else if (clusters && clusters.length === 0) {
<app-dashboard-empty></app-dashboard-empty>
}
