<div class="code">
  <div class="code__heading">
    <label>{{ (title | translate).toUpperCase() }}</label>

    @if (copyable) {
    <app-copy-tooltip [value]="data">
      <mat-icon class="code__copy-icon" fontSet="material-icons-outlined">
        content_copy
      </mat-icon>
    </app-copy-tooltip>
    }
  </div>

  <div class="code__content">
    <textarea
      cdkTextareaAutosize
      [value]="data"
      class="code__textarea"
      disabled
    ></textarea>
  </div>
</div>
