import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';

@Component({
  selector: 'app-popover',
  standalone: true,
  imports: [SharedModule, MaterialModule],
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent {
  @Input() origin: any; // CdkConnectedOverlay
  @Input() positions?: ConnectedPosition[];
  @Input() active = false;
  @Input() padding: 'small' | 'none' = 'none';

  @Output() inactive = new EventEmitter();
}
