<div class="login">
  <h1 translate>login.loginToMyDeeploy</h1>

  <form class="login__form" [formGroup]="loginForm">
    @if (hasFailedLogin) {
    <app-bubble
      [type]="Bubble.ERROR"
      [text]="'login.incorrectLogin' | translate"
    ></app-bubble>
    } @else if (completedSignUp) {
    <app-bubble
      [type]="Bubble.SUCCESS"
      [text]="'login.passwordCreated' | translate"
    ></app-bubble>
    }

    <mat-form-field appearance="outline" class="login__email">
      <input
        matInput
        [autofocus]="true"
        [placeholder]="'common.email' | translate"
        formControlName="email"
        type="text"
        autocomplete="email"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="login__password">
      <input
        matInput
        [type]="isPasswordVisible ? 'text' : 'password'"
        [placeholder]="'common.password' | translate"
        formControlName="password"
        autocomplete="current-password"
      />
      <span matSuffix (click)="isPasswordVisible = !isPasswordVisible">
        @if (isPasswordVisible) {
        <mat-icon fontSet="material-icons-outlined">visibility_off</mat-icon>
        } @else {
        <mat-icon fontSet="material-icons-outlined">visibility</mat-icon>
        }
      </span>
    </mat-form-field>
  </form>

  <a href="/forgot-password" translate>login.forgotPassword</a>

  <app-primary-button
    (clicked)="login()"
    [text]="'login.login' | translate"
    width="full"
  ></app-primary-button>
</div>
