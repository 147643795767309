import { Component } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AbstractControl, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { FunctionsService } from '../../../services/functions/functions.service';
import { PrimaryButtonComponent } from '../../common/buttons/primary-button/primary-button.component';
import { SpinnerComponent } from '../../common/spinner/spinner.component';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    SharedModule,
    MaterialModule,
    PrimaryButtonComponent,
    AngularFireAuthModule,
    RouterModule,
    SpinnerComponent,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  public emailForm: UntypedFormGroup;
  public isCreatingLink = false;
  public isLinkSent = false;

  public get email(): AbstractControl {
    return this.emailForm.get('email');
  }

  constructor(
    private fb: FormBuilder,
    private functionsService: FunctionsService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  public async sendPasswordResetEmail(): Promise<void> {
    if (this.emailForm.invalid) return;

    this.isCreatingLink = true;

    await this.functionsService.call('customer-resetPassword', {
      email: this.email.value,
    });

    this.isLinkSent = true;
    this.isCreatingLink = false;
  }

  public resetState(): void {
    this.isLinkSent = false;
    this.email.reset();
  }

  public navigateToLogin(): void {
    this.router.navigate(['/login']);
  }
}
