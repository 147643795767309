<div
  class="bubble"
  [ngClass]="{
    'bubble--info': type === Bubble.INFO,
    'bubble--error': type === Bubble.ERROR,
    'bubble--warning': type === Bubble.WARNING,
    'bubble--success': type === Bubble.SUCCESS
  }"
>
  <div class="bubble__icon-container">
    @switch (type) { @case (Bubble.INFO) {
    <mat-icon fontSet="material-icons-outlined" class="bubble__icon"
      >info</mat-icon
    >} @case (Bubble.ERROR) {
    <mat-icon fontSet="material-icons-outlined" class="bubble__icon"
      >error_outline</mat-icon
    >} @case (Bubble.WARNING) {
    <mat-icon fontSet="material-icons-outlined" class="bubble__icon"
      >warning_amber</mat-icon
    >} @case (Bubble.SUCCESS) {
    <mat-icon fontSet="material-icons-outlined" class="bubble__icon"
      >check_circle</mat-icon
    >} }
  </div>

  @if (text) {
  <p class="bubble__text">{{ text }}</p>
  }

  <div class="bubble__text">
    <ng-content select="[text]"></ng-content>
  </div>
</div>
