import { Component } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';

import { DocumentationURL } from '../../enums/documentation-url.enum';
import { ExternalURL } from '../../enums/external-url.enum';
import { IImageSecret } from '../../interfaces/image-secret.interface';
import { ILicense } from '../../interfaces/license.interface';
import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { CodeComponent } from '../common/code/code.component';
import { CopyTooltipComponent } from '../common/copy-tooltip/copy-tooltip.component';
import { TooltipComponent } from '../common/tooltip/tooltip.component';
import { InstallationCardComponent } from './installation-card/installation-card.component';
import { WelcomeDialogComponent } from './welcome-dialog/welcome-dialog.component';

@Component({
  selector: 'app-installation',
  standalone: true,
  imports: [
    SharedModule,
    InstallationCardComponent,
    CodeComponent,
    TooltipComponent,
    CopyTooltipComponent,
    AngularFireAuthModule,
    MaterialModule,
    RouterModule,
  ],
  templateUrl: './installation.component.html',
  styleUrl: './installation.component.scss',
})
export class InstallationComponent {
  public DocumentationURL = DocumentationURL;
  public ExternalURL = ExternalURL;
  public licenseValues: string;
  public imageValues: string;
  public statusValues: string;
  public licenseSub$: Subscription;
  public imageSub$: Subscription;

  constructor(
    private firestoreService: FirestoreService,
    private auth: Auth,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    if (this.route.snapshot.queryParams['isFirstLogin'] === 'true') {
      this.displayWelcomeDialog();
    }

    this.setLicenseValues();
    this.setImageValues();
    this.setStatusValues();
  }

  public ngOnDestroy(): void {
    if (this.licenseSub$) this.licenseSub$.unsubscribe();
    if (this.imageSub$) this.imageSub$.unsubscribe();
  }

  public async setLicenseValues(): Promise<void> {
    this.licenseSub$ = this.firestoreService
      .getCollectionSnapshot<ILicense>(`customers/${this.auth.currentUser.uid}/licenses`)
      .subscribe((licenses) => {
        const license = licenses[0];
        let licenseKey: string;

        if (license.active) {
          licenseKey = licenses[0].licenseKey;
        } else {
          licenseKey = '# inactive';
        }

        this.licenseValues = `license:
  type: DEEPLOY
  deeployLicenseKey: ${licenseKey}`;
      });
  }

  public async setImageValues(): Promise<void> {
    this.imageSub$ = this.firestoreService
      .getCollectionSnapshot<IImageSecret>(`customers/${this.auth.currentUser.uid}/imageSecrets`)
      .subscribe((imageSecrets) => {
        const imageSecret = imageSecrets[0];

        this.imageValues = `images:
  registry: ${imageSecret.registry}
  path: ${imageSecret.path}
  username: ${imageSecret.username}
  password: ${imageSecret.password}`;
      });
  }

  public setStatusValues(): void {
    this.statusValues = `statusPage:
  enabled: true`;
  }

  public displayWelcomeDialog(): void {
    this.dialog.open(WelcomeDialogComponent);
  }
}
