<div class="welcome-dialog">
  <mat-icon
    (click)="close()"
    class="welcome-dialog__close-button"
    fontSet="material-icons-outlined"
    >close</mat-icon
  >

  <h2 translate>installation.welcomeToMyDeeploy</h2>

  <p
    [innerHTML]="'installation.getStarted' | translate"
    class="welcome-dialog__text-block"
  ></p>

  <p class="welcome-dialog__text-block">
    <b>{{ "installation.lookingToTransfer" | translate }}</b>
  </p>
  <p translate>installation.headToYourAccount</p>

  <div class="welcome-dialog__buttons">
    <app-secondary-button
      (clicked)="navigateToAccount()"
      [text]="'installation.goToAccount' | translate"
    ></app-secondary-button>

    <app-primary-button
      (clicked)="close()"
      [text]="'installation.installDeeploy' | translate"
    ></app-primary-button>
  </div>
</div>
