import { Component, Input } from '@angular/core';

import { IPasswordRequirements } from '../../../interfaces/password-requirements.interface';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';

@Component({
  selector: 'app-password-requirements',
  standalone: true,
  imports: [SharedModule, MaterialModule],
  templateUrl: './password-requirements.component.html',
  styleUrls: ['./password-requirements.component.scss'],
})
export class PasswordRequirementsComponent {
  @Input() requirements: IPasswordRequirements;
  @Input() position: 'horizontal' | 'vertical' = 'vertical';
}
