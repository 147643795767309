<form [formGroup]="passwordForm" class="sign-up">
  <h1 translate>signUp.createPassword</h1>
  <p translate>signUp.pleaseCreate</p>

  <float-ui-content #passwordTooltip>
    @if (requirements) {
    <app-password-requirements
      [requirements]="requirements"
      position="horizontal"
    ></app-password-requirements>
    }
  </float-ui-content>

  <mat-form-field
    [floatUi]="passwordTooltip"
    [disabled]="true"
    [placement]="NgxFloatUiPlacements.TOP"
    applyClass="popper-content"
    applyArrowClass="popper-content-arrow"
    appearance="outline"
    class="sign-up__password"
  >
    <input
      matInput
      (input)="updateRequirements($event)"
      (focusin)="passwordTooltip.show()"
      (blur)="passwordTooltip.hide()"
      [type]="isPasswordVisible ? 'text' : 'password'"
      [placeholder]="'common.password' | translate"
      formControlName="password"
      autocomplete="new-password"
    />
    <span matSuffix (click)="isPasswordVisible = !isPasswordVisible">
      @if (isPasswordVisible) {
      <mat-icon fontSet="material-icons-outlined">visibility_off</mat-icon>
      } @else {
      <mat-icon fontSet="material-icons-outlined">visibility</mat-icon>
      }
    </span>
  </mat-form-field>

  <app-primary-button
    (clicked)="setPassword()"
    [disabled]="passwordForm.invalid"
    [text]="'signUp.savePassword' | translate"
    width="full"
  ></app-primary-button>
</form>
