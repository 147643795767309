<button
  (click)="$event.stopPropagation(); areOptionsOpen = true"
  class="options-button"
  cdkOverlayOrigin
  #optionsTrigger="cdkOverlayOrigin"
>
  <mat-icon fontSet="material-icons-outlined"> more_horiz </mat-icon>
</button>

<app-popover
  [active]="areOptionsOpen"
  (inactive)="areOptionsOpen = false"
  [origin]="optionsTrigger"
  [positions]="positions"
  padding="small"
>
  <ng-content></ng-content>
</app-popover>
