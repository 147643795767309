<div class="account">
  <h1 translate>account.account</h1>

  @if (editAccountForm) {
  <form [formGroup]="editAccountForm" class="account__edit-account-form">
    <mat-form-field appearance="outline" class="account__name">
      <input
        matInput
        [placeholder]="'account.name' | translate"
        formControlName="name"
        type="text"
        autocomplete="name"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="account__organization">
      <input
        matInput
        [placeholder]="'account.organization' | translate"
        formControlName="organization"
        type="text"
        autocomplete="organization"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="account__email">
      <input
        matInput
        [placeholder]="'common.email' | translate"
        formControlName="email"
        type="text"
        autocomplete="email"
      />
    </mat-form-field>

    <app-secondary-button
      (clicked)="saveAccountChanges()"
      [disabled]="editAccountForm.invalid"
      [text]="'account.saveChanges' | translate"
      icon="save"
    ></app-secondary-button>
  </form>
  }

  <h1 translate>account.changePassword</h1>

  @if (editPasswordForm) {
  <div class="account__password-grid">
    <div class="account__requirements">
      <p translate>account.passwordRequirements</p>

      <app-password-requirements
        [requirements]="requirements"
      ></app-password-requirements>
    </div>

    <form [formGroup]="editPasswordForm" class="account__edit-password-form">
      <mat-form-field appearance="outline" class="account__password">
        <input
          matInput
          (input)="updateRequirements($event)"
          [placeholder]="'account.newPassword' | translate"
          [type]="isNewPasswordVisible ? 'text' : 'password'"
          formControlName="newPassword"
          autocomplete="new-password"
        />
        <span matSuffix (click)="isNewPasswordVisible = !isNewPasswordVisible">
          @if (isNewPasswordVisible) {
          <mat-icon fontSet="material-icons-outlined">visibility_off</mat-icon>
          } @else {
          <mat-icon fontSet="material-icons-outlined">visibility</mat-icon>
          }
        </span>
      </mat-form-field>

      <mat-form-field appearance="outline" class="account__password">
        <input
          matInput
          [placeholder]="'account.currentPassword' | translate"
          [type]="isCurrentPasswordVisible ? 'text' : 'password'"
          formControlName="currentPassword"
          autocomplete="current-password"
        />
        <span
          matSuffix
          (click)="isCurrentPasswordVisible = !isCurrentPasswordVisible"
        >
          @if (isCurrentPasswordVisible) {
          <mat-icon fontSet="material-icons-outlined">visibility_off</mat-icon>
          } @else {
          <mat-icon fontSet="material-icons-outlined">visibility</mat-icon>
          }
        </span>
      </mat-form-field>

      <app-secondary-button
        (clicked)="saveNewPassword()"
        [disabled]="editPasswordForm.invalid"
        [text]="'account.saveNewPassword' | translate"
        icon="save"
      ></app-secondary-button>
    </form>
  </div>
  }
</div>
