import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';

import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { PrimaryButtonComponent } from '../../common/buttons/primary-button/primary-button.component';
import { SecondaryButtonComponent } from '../../common/buttons/secondary-button/secondary-button.component';

@Component({
  selector: 'app-welcome-dialog',
  standalone: true,
  imports: [SharedModule, MaterialModule, SecondaryButtonComponent, PrimaryButtonComponent, RouterModule],
  templateUrl: './welcome-dialog.component.html',
  styleUrl: './welcome-dialog.component.scss',
})
export class WelcomeDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<WelcomeDialogComponent>,
    private router: Router,
  ) {}

  public close(): void {
    this.dialogRef.close();
  }

  public navigateToAccount(): void {
    this.router.navigate(['/account']);
    this.close();
  }
}
