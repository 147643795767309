import { AvatarColor } from '../enums/avatar-color';

export const LETTER_COLORS = {
  a: AvatarColor.VIOLET,
  b: AvatarColor.GRAPEFRUIT,
  c: AvatarColor.RED,
  d: AvatarColor.ORANGE,
  e: AvatarColor.YELLOW,
  f: AvatarColor.GREEN,
  g: AvatarColor.DARK_GREEN,
  h: AvatarColor.AQUA,
  i: AvatarColor.BROWN,
  j: AvatarColor.VIOLET,
  k: AvatarColor.PINK,
  l: AvatarColor.PINK,
  m: AvatarColor.VIOLET,
  n: AvatarColor.BLUE,
  o: AvatarColor.AQUA,
  p: AvatarColor.DARK_GREEN,
  q: AvatarColor.GREEN,
  r: AvatarColor.YELLOW,
  s: AvatarColor.ORANGE,
  t: AvatarColor.RED,
  u: AvatarColor.GRAPEFRUIT,
  v: AvatarColor.BROWN,
  w: AvatarColor.VIOLET,
  x: AvatarColor.GREEN,
  y: AvatarColor.AQUA,
  z: AvatarColor.ORANGE,
  '-': AvatarColor.RED,
};
