import { Component, Inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { IClusterDialogData } from '../../../interfaces/cluster-dialog-data.interface';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { FirestoreService } from '../../../services/firestore/firestore.service';
import { PrimaryButtonComponent } from '../../common/buttons/primary-button/primary-button.component';
import { SecondaryButtonComponent } from '../../common/buttons/secondary-button/secondary-button.component';
import { DialogComponent } from '../../common/dialog/dialog.component';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-edit-cluster-dialog',
  standalone: true,
  imports: [
    SharedModule,
    MaterialModule,
    DialogComponent,
    AngularFireAuthModule,
    SecondaryButtonComponent,
    PrimaryButtonComponent,
  ],
  templateUrl: './edit-cluster-dialog.component.html',
  styleUrl: './edit-cluster-dialog.component.scss',
})
export class EditClusterDialogComponent {
  public clusterForm: UntypedFormGroup;

  public get name(): AbstractControl {
    return this.clusterForm.get('name');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: IClusterDialogData,
    private dialogRef: MatDialogRef<EditClusterDialogComponent>,
    private fb: UntypedFormBuilder,
    private firestoreService: FirestoreService,
    private auth: Auth,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.createClusterForm();
  }

  public createClusterForm(): void {
    this.clusterForm = this.fb.group({
      name: [this.data.name, [Validators.required]],
    });
  }

  public async editClusterName(): Promise<void> {
    await this.firestoreService
      .updateDocument(`customers/${this.auth.currentUser.uid}/clusters/${this.data.id}`, {
        name: this.name.value,
      })
      .then(() => {
        const successMessage = this.translate.instant('common.changesSaved');
        this.snackBarService.openSuccessSnackBar(successMessage);
      })
      .catch(() => {
        const errorMessage = this.translate.instant('account.failedUpdateName');
        this.snackBarService.openFailedSnackBar(errorMessage);
      })
      .finally(() => {
        this.close(true);
      });
  }

  public close(confirmed?: boolean): void {
    this.dialogRef.close(confirmed);
  }
}
