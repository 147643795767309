@if (requirements) {
<div class="requirements requirements--{{ position }}">
  @for (requirement of requirements | keyvalue; track requirement.key) {
  <div class="requirements__requirement">
    @if (requirement.value) {
    <div class="requirements__icon"><mat-icon>done</mat-icon></div>
    } @else {
    <div class="requirements__icon"><span>&#8226;</span></div>
    }

    <p
      class="requirements__label {{ requirement.value ? 'success' : '' }}"
      translate
    >
      common.passwordRequirements.{{ requirement.key }}
    </p>
  </div>
  }
</div>
}
