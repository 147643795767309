import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

const COMMON_COMPONENTS = [CommonModule, TranslateModule];

@NgModule({
  exports: COMMON_COMPONENTS,
  imports: COMMON_COMPONENTS,
})
export class SharedModule {}
