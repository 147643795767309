import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [SharedModule, MaterialModule],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent {
  @Input() title: string;
  @Input() hasScrollbar = false;

  @Output() closed = new EventEmitter();

  public close(): void {
    this.closed.emit();
  }
}
