<app-dialog
  (closed)="close(false)"
  [title]="'cluster.editClusterName' | translate"
>
  <form [formGroup]="clusterForm" class="edit-cluster" content>
    <h3 translate>cluster.clusterName</h3>
    <p translate>cluster.giveClusterName</p>

    <mat-form-field appearance="outline">
      <input matInput formControlName="name" type="text" autocomplete="off" />
    </mat-form-field>
  </form>

  <ng-container right-buttons>
    <app-secondary-button
      (clicked)="close(false)"
      [text]="'common.cancel' | translate"
      color="error"
    ></app-secondary-button>

    <app-primary-button
      (clicked)="editClusterName()"
      [text]="'common.save' | translate"
      [disabled]="clusterForm.invalid"
    ></app-primary-button>
  </ng-container>
</app-dialog>
