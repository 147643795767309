import { MatTooltip } from '@angular/material/tooltip';

export async function copyToClipboard(value: string, tooltip: MatTooltip, button?: HTMLButtonElement): Promise<void> {
  tooltip.hide();
  await navigator.clipboard
    .writeText(value)
    .then(() => {
      tooltip.message = 'Copied!';
      tooltip.show();
      setTimeout(() => {
        tooltip.hide();
        setTimeout(() => {
          tooltip.message = 'Copy to clipboard';
        }, 100);
      }, 1000);
      if (button) button.focus();
    })
    .catch(() => {
      tooltip.message = 'Failed to copy';
      setTimeout(() => {
        tooltip.hide();
        setTimeout(() => {
          tooltip.message = 'Copy to clipboard';
        }, 100);
      }, 1000);
    });
}
