import { Component } from '@angular/core';
import { Auth, confirmPasswordReset } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgxFloatUiModule, NgxFloatUiPlacements } from 'ngx-float-ui';

import {
  LOWERCASE_REGEX,
  NUMBER_REGEX,
  PASSWORD_REQUIREMENTS,
  SPECIAL_CHAR_REGEX,
  UPPERCASE_REGEX,
} from '../../../constants/password-requirements';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { regexValidator } from '../../../validators/regex.validator';
import { PrimaryButtonComponent } from '../../common/buttons/primary-button/primary-button.component';
import { PasswordRequirementsComponent } from '../../common/password-requirements/password-requirements.component';
import { FireAuthService } from '../../../services/fire-auth/fire-auth.service';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    PrimaryButtonComponent,
    NgxFloatUiModule,
    PasswordRequirementsComponent,
    AngularFireAuthModule,
  ],
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss',
})
export class SignUpComponent {
  public passwordForm: FormGroup;
  public isPasswordVisible = false;
  public isValidPassword = false;
  public requirements = structuredClone(PASSWORD_REQUIREMENTS);
  public NgxFloatUiPlacements = NgxFloatUiPlacements;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private auth: Auth,
    private fireAuthService: FireAuthService,
  ) {}

  public ngOnInit(): void {
    this.createPasswordForm();
  }

  public createPasswordForm(): void {
    this.passwordForm = this.fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(12),
          regexValidator(new RegExp(UPPERCASE_REGEX), { upperCase: true }),
          regexValidator(new RegExp(LOWERCASE_REGEX), { lowerCase: true }),
          regexValidator(new RegExp(NUMBER_REGEX), { number: true }),
          regexValidator(new RegExp(SPECIAL_CHAR_REGEX), { specialChar: true }),
        ],
      ],
    });
  }

  public async setPassword(): Promise<void> {
    const oobCode = this.route.snapshot.queryParams['oobCode'];

    await this.fireAuthService
      .confirmPasswordReset(this.auth, oobCode, this.passwordForm.get('password').value)
      .then(() => {
        const continueUrl = this.route.snapshot.queryParams['continueUrl'];
        window.location.href = continueUrl;
      });
  }

  public updateRequirements(event: Event): void {
    this.requirements.minLength = event.target['value'].length >= 12;
    this.requirements.specialChar = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(event.target['value']);
    this.requirements.upperCase = /[A-Z]/.test(event.target['value']);
    this.requirements.lowerCase = /[a-z]/.test(event.target['value']);
    this.requirements.number = /[0-9]/.test(event.target['value']);
    this.isValidPassword = Object.values(this.requirements).every((requirement) => requirement === true);
  }
}
