import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackbarComponent } from '../../components/common/snackbar/snackbar.component';
import { ISnackbarData } from '../../interfaces/snack-bar-data.interface';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private readonly SNACK_BAR_DURATION = 4000; // 4s

  constructor(private snackBar: MatSnackBar) {}

  public openSuccessSnackBar(message: string): void {
    const data: ISnackbarData = {
      message: message,
      icon: 'check_circle',
      preClose: () => {
        this.snackBar.dismiss();
      },
    };

    this.snackBar.openFromComponent(SnackbarComponent, { data, duration: this.SNACK_BAR_DURATION });
  }

  public openFailedSnackBar(message: string): void {
    const data: ISnackbarData = {
      message: message,
      icon: 'cancel',
      preClose: () => {
        this.snackBar.dismiss();
      },
    };

    this.snackBar.openFromComponent(SnackbarComponent, { data, duration: this.SNACK_BAR_DURATION });
  }
}
