import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNotEmpty',
  standalone: true,
})
export class IsNotEmptyPipe implements PipeTransform {
  public transform(value: string): boolean {
    if (value !== undefined && value !== null && value !== '') {
      return true;
    } else {
      return false;
    }
  }
}
