import { Component } from '@angular/core';

import { OverlayModule } from '@angular/cdk/overlay';
import { PopoverComponent } from '../../popover/popover.component';
import { SharedModule } from '../../../../modules/shared.module';
import { MaterialModule } from '../../../../modules/material.module';
import { BOTTOM_LEFT_POSITION } from '../../../../constants/positions';

@Component({
  selector: 'app-options-button',
  standalone: true,
  imports: [SharedModule, MaterialModule, PopoverComponent, OverlayModule],
  templateUrl: './options-button.component.html',
  styleUrls: ['./options-button.component.scss'],
})
export class OptionsButtonComponent {
  public areOptionsOpen = false;
  public positions = BOTTOM_LEFT_POSITION;
}
