import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { copyToClipboard } from '../../../functions/copy-to-clipboard';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { CopyTooltipComponent } from '../copy-tooltip/copy-tooltip.component';

@Component({
  selector: 'app-code',
  standalone: true,
  imports: [SharedModule, MaterialModule, CopyTooltipComponent],
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
})
export class CodeComponent {
  @Input() data: string;
  @Input() copyable = false;
  @Input() title: string;

  public copyToClipboard(
    dataBody: Record<string, unknown> | string,
    tooltip: MatTooltip,
    button?: HTMLButtonElement,
  ): void {
    const string = typeof dataBody === 'object' ? JSON.stringify(dataBody) : dataBody;
    copyToClipboard(string, tooltip, button);
  }
}
