@if (isDisplayed) {
<div class="spinner spinner__margin-{{ margin }} spinner__align-{{ align }}">
  <img
    src="/public/common/spinner.gif"
    alt="Spinner"
    class="spinner__gif-{{ size }}"
  />

  @if (label) {
  <p class="spinner__label">{{ label | translate }}</p>
  }
</div>
}
