import { Component, Input, SimpleChanges } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { hasChanges } from '../../../functions/has-changes';
import { IClusterDialogData } from '../../../interfaces/cluster-dialog-data.interface';
import { ICluster } from '../../../interfaces/cluster.interface';
import { IConfirmDeletionDialogData } from '../../../interfaces/confirm-deletion-dialog-data.interface';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { IsNotEmptyPipe } from '../../../pipes/is-not-empty/is-not-empty.pipe';
import { TimestampPipe } from '../../../pipes/timestamp/timestamp.pipe';
import { FirestoreService } from '../../../services/firestore/firestore.service';
import { SnackBarService } from '../../../services/snack-bar/snack-bar.service';
import { OptionsButtonComponent } from '../../common/buttons/options-button/options-button.component';
import { ConfirmDeletionDialogComponent } from '../../common/confirm-deletion-dialog/confirm-deletion-dialog.component';
import { CopyTooltipComponent } from '../../common/copy-tooltip/copy-tooltip.component';
import { PopoverItemComponent } from '../../common/popover/popover-item/popover-item.component';
import { EditClusterDialogComponent } from '../edit-cluster-dialog/edit-cluster-dialog.component';

@Component({
  selector: 'app-cluster-card',
  standalone: true,
  imports: [
    SharedModule,
    OptionsButtonComponent,
    CopyTooltipComponent,
    MaterialModule,
    TimestampPipe,
    IsNotEmptyPipe,
    AngularFireAuthModule,
    PopoverItemComponent,
  ],
  templateUrl: './cluster-card.component.html',
  styleUrl: './cluster-card.component.scss',
})
export class ClusterCardComponent {
  @Input() cluster: ICluster;

  public maskedSecret: string;
  public deleteDialogSub$: Subscription;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private firestoreService: FirestoreService,
    private auth: Auth,
    private snackBarService: SnackBarService,
  ) {}

  public ngOnChanges({ cluster }: SimpleChanges): void {
    const changedCluster = hasChanges(cluster);

    if (changedCluster && this.cluster) {
      this.maskedSecret = this.getMaskedSecret(this.cluster.clusterSecret);
    }
  }

  public ngOnDestroy(): void {
    if (this.deleteDialogSub$) this.deleteDialogSub$.unsubscribe();
  }

  public openEditNameDialog(): void {
    const data: IClusterDialogData = {
      id: this.cluster.id,
      name: this.cluster.name,
    };

    this.dialog.open(EditClusterDialogComponent, { data });
  }

  public openDeleteClusterDialog(): void {
    const data: IConfirmDeletionDialogData = {
      title: this.translate.instant('cluster.sureAboutDeleting'),
      description: this.translate.instant('cluster.actionIsIrreversible'),
    };

    const dialogRef = this.dialog.open(ConfirmDeletionDialogComponent, { data });

    this.deleteDialogSub$ = dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.firestoreService
          .deleteDocument(`customers/${this.auth.currentUser.uid}/clusters/${this.cluster.id}`)
          .then(() => {
            const successMessage = this.translate.instant('cluster.deletedCluster', { name: this.cluster.name });
            this.snackBarService.openSuccessSnackBar(successMessage);
          })
          .catch(() => {
            const errorMessage = this.translate.instant('cluster.failedDelete');
            this.snackBarService.openFailedSnackBar(errorMessage);
          });
      }
    });
  }

  public getMaskedSecret(secret: string): string {
    const start = secret.substring(0, 4);
    const end = secret.substring(secret.length - 4);
    return `${start}...${end}`;
  }
}
