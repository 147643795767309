import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxFloatUiModule, NgxFloatUiPlacements } from 'ngx-float-ui';

import { SharedModule } from '../../../modules/shared.module';

@Component({
  selector: 'app-copy-tooltip',
  standalone: true,
  imports: [SharedModule, NgxFloatUiModule],
  templateUrl: './copy-tooltip.component.html',
  styleUrls: ['./copy-tooltip.component.scss'],
})
export class CopyTooltipComponent {
  @Input() placement: NgxFloatUiPlacements = NgxFloatUiPlacements.TOP;
  @Input() value: string;

  public label: string;

  constructor(private translate: TranslateService) {}

  public ngOnInit(): void {
    this.label = this.translate.instant('common.clickToCopy');
  }

  public async copyValue(): Promise<void> {
    await navigator.clipboard.writeText(this.value);
    this.label = this.translate.instant('common.copied');

    setTimeout(() => {
      this.label = this.translate.instant('common.clickToCopy');
    }, 1000);
  }
}
