<div class="toolbar">
  <div class="toolbar__logo">
    <img src="/public/logos/logo.gif" alt="Deeploy logo" />
    <p translate>toolbar.myDeeploy</p>
  </div>

  <div class="toolbar__items">
    <app-toolbar-item
      [active]="currentPage === Page.DASHBOARD"
      [navRouterLink]="Page.DASHBOARD"
      [label]="'toolbar.dashboard' | translate"
    ></app-toolbar-item>

    <app-toolbar-item
      [active]="currentPage === Page.INSTALLATION"
      [navRouterLink]="Page.INSTALLATION"
      [label]="'toolbar.installation' | translate"
    ></app-toolbar-item>

    <app-toolbar-item
      (click)="createBillingPortal()"
      [active]="currentPage === Page.CUSTOMER_PORTAL"
      [label]="'toolbar.customerPortal' | translate"
      icon="open_in_new"
    ></app-toolbar-item>
  </div>

  <app-avatar
    cdkOverlayOrigin
    #avatarTrigger="cdkOverlayOrigin"
    (click)="isAvatarPopoverOpen = true"
    [letter]="user.displayName.charAt(0)"
  ></app-avatar>

  <app-popover
    (inactive)="isAvatarPopoverOpen = false"
    [active]="isAvatarPopoverOpen"
    [origin]="avatarTrigger"
    padding="small"
  >
    <app-popover-item
      (click)="navigateToAccount()"
      [label]="'toolbar.account' | translate"
      icon="person"
    ></app-popover-item>

    <app-popover-item
      (click)="logOut()"
      [label]="'toolbar.logOut' | translate"
      icon="logout"
    ></app-popover-item>
  </app-popover>
</div>
