<div class="dialog">
  <div class="dialog__header">
    <h2>{{ title }}</h2>

    <button (click)="close()" class="dialog__close-button">
      <mat-icon fontSet="material-icons-outlined">close</mat-icon>
    </button>
  </div>

  <div [ngClass]="{ 'dialog__body--no-scrollbar': !hasScrollbar }" class="dialog__body">
    <ng-content select="[content]"></ng-content>
  </div>

  <div class="dialog__buttons">
    <div class="dialog__left-buttons">
      <ng-content select="[left-buttons]"></ng-content>
    </div>

    <div class="dialog__right-buttons">
      <ng-content select="[right-buttons]"></ng-content>
    </div>
  </div>
</div>
