import { Component } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { firstValueFrom, Subscription } from 'rxjs';

import { ICluster } from '../../interfaces/cluster.interface';
import { IStatistics } from '../../interfaces/statistics.interface';
import { SharedModule } from '../../modules/shared.module';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { ClusterCardComponent } from './cluster-card/cluster-card.component';
import { DashboardEmptyComponent } from './dashboard-empty/dashboard-empty.component';
import { EntityCardComponent } from './entity-card/entity-card.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [SharedModule, EntityCardComponent, AngularFireAuthModule, ClusterCardComponent, DashboardEmptyComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  public clusters: ICluster[];
  public clustersSub$: Subscription;
  public statistics: IStatistics[];

  constructor(
    private firestoreService: FirestoreService,
    private auth: Auth,
  ) {}

  public ngOnInit(): void {
    this.listenToClusters();
  }

  public ngOnDestroy(): void {
    if (this.clustersSub$) this.clustersSub$.unsubscribe();
  }

  public async listenToClusters(): Promise<void> {
    this.clustersSub$ = this.firestoreService
      .getCollectionSnapshot<ICluster>(`customers/${this.auth.currentUser.uid}/clusters`)
      .subscribe((clusters) => {
        this.clusters = clusters;
        this.getStatistics();
      });
  }

  public async getStatistics(): Promise<void> {
    const statistics: IStatistics[] = [];

    for (let cluster of this.clusters) {
      const statisticsDoc = await firstValueFrom(
        this.firestoreService.getCollectionSnapshot<IStatistics>(
          `customers/${this.auth.currentUser.uid}/clusters/${cluster.id}/statistics`,
          'createdAt',
          'desc',
        ),
      );

      if (statisticsDoc[0]) {
        statistics.push(statisticsDoc[0]);
      }
    }

    this.statistics = statistics;
  }

  public statisticsSumByField(fieldName: string): number {
    return this.statistics.reduce((prev, curr) => prev + curr[fieldName], 0);
  }
}
