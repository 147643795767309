<div class="confirm-delete">
  <mat-icon
    (click)="close(false)"
    class="confirm-delete__close-button"
    fontSet="material-icons-outlined"
    >close</mat-icon
  >

  <h2>{{ title }}</h2>
  <p class="confirm-delete__description">{{ description }}</p>

  <form [formGroup]="deleteForm" class="confirm-delete__delete-container">
    <p>
      {{ "common.confirmByTyping" | translate }}
      <b>'{{ confirmLabel }}'</b>
    </p>

    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <input
        matInput
        formControlName="confirmByTyping"
        type="text"
        autocomplete="off"
      />
    </mat-form-field>
  </form>

  <div class="confirm-delete__buttons">
    <app-secondary-button
      (clicked)="close(false)"
      [text]="'common.cancel' | translate"
    ></app-secondary-button>

    <app-primary-button
      (clicked)="confirm()"
      [text]="'common.delete' | translate"
      [disabled]="confirmLabel !== confirmByTyping.value"
      icon="delete"
      color="error"
    ></app-primary-button>
  </div>
</div>
