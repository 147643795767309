<div
  [routerLink]="navRouterLink"
  [ngClass]="{ 'popover-item--active': active }"
  class="popover-item"
>
  @if (icon) {
  <mat-icon fontSet="material-icons-outlined" class="popover-item__icon">{{
    icon
  }}</mat-icon>
  }

  <span class="popover-item__heading">{{ label }}</span>
</div>
