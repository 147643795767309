<ng-template
  cdkConnectedOverlay
  (backdropClick)="inactive.emit()"
  [cdkConnectedOverlayOffsetY]="8"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'backdrop'"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="active"
>
  <div class="popover popover--padding-{{ padding }}">
    <ul class="popover__items">
      <ng-content></ng-content>
    </ul>
  </div>
</ng-template>
