import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  collectionData,
  docData,
  query,
  orderBy,
  CollectionReference,
  DocumentReference,
  updateDoc,
  deleteDoc,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(private firestore: Firestore) {}

  public getCollectionRef(path: string, sortBy?: string, sortDirection: 'desc' | 'asc' = 'asc'): CollectionReference {
    const collectionRef = collection(this.firestore, path);

    if (sortBy === undefined) {
      return collectionRef;
    } else {
      return query(collectionRef, orderBy(sortBy, sortDirection)) as CollectionReference;
    }
  }

  public getDocumentRef(path: string): DocumentReference {
    return doc(this.firestore, path);
  }

  public getCollectionSnapshot<T>(path: string, sortBy?: string, sortDirection?: 'desc' | 'asc'): Observable<T[]> {
    const collectionRef = this.getCollectionRef(path, sortBy, sortDirection);
    return collectionData(collectionRef, { idField: 'id' }) as Observable<T[]>;
  }

  public getDocumentSnapshot<T>(path: string): Observable<T> {
    const documentRef = this.getDocumentRef(path);
    return docData(documentRef, { idField: 'id' }) as Observable<T>;
  }

  public getDocument<T>(path: string): Observable<T> {
    return this.getDocumentSnapshot<T>(path);
  }

  public async updateDocument(path: string, data: object): Promise<void> {
    const documentRef = this.getDocumentRef(path);
    await updateDoc(documentRef, data);
  }

  public async deleteDocument(path: string): Promise<void> {
    const documentRef = this.getDocumentRef(path);
    await deleteDoc(documentRef);
  }
}
