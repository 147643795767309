import { Component, Input } from '@angular/core';

import { SharedModule } from '../../../modules/shared.module';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() label: string;
  @Input() margin: 'none' | 'small' | 'medium' = 'medium';
  @Input() align: 'left' | 'center' = 'center';
  @Input() waitForMs?: number = 1000; // 1s
  @Input() size: 'small' | 'medium' = 'medium';

  public isDisplayed = false;

  public ngOnInit(): void {
    setTimeout(() => {
      this.isDisplayed = true;
    }, this.waitForMs);
  }
}
