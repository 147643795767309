import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../../../../modules/material.module';
import { SharedModule } from '../../../../modules/shared.module';

@Component({
  selector: 'app-toolbar-item',
  standalone: true,
  imports: [SharedModule, RouterModule, MaterialModule],
  templateUrl: './toolbar-item.component.html',
  styleUrl: './toolbar-item.component.scss',
})
export class ToolbarItemComponent {
  @Input() label: string;
  @Input() navRouterLink: string;
  @Input() active: boolean;
  @Input() icon: string;
}
