import { Component, Input } from '@angular/core';

import { Bubble } from '../../../enums/bubble.enum';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';

@Component({
  selector: 'app-bubble',
  standalone: true,
  imports: [SharedModule, MaterialModule],
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss'],
})
export class BubbleComponent {
  @Input() type: Bubble;
  @Input() text?: string;

  public Bubble = Bubble;
}
