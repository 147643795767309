import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { SharedModule } from '../../../modules/shared.module';
import { SecondaryButtonComponent } from '../../common/buttons/secondary-button/secondary-button.component';

@Component({
  selector: 'app-dashboard-empty',
  standalone: true,
  imports: [SharedModule, SecondaryButtonComponent, RouterModule],
  templateUrl: './dashboard-empty.component.html',
  styleUrl: './dashboard-empty.component.scss',
})
export class DashboardEmptyComponent {
  constructor(private router: Router) {}

  public navigateToInstallation(): void {
    this.router.navigate(['/installation']);
  }
}
