import { Routes } from '@angular/router';

import { AccountComponent } from './components/account/account.component';
import { AuthLayoutComponent } from './components/auth/auth-layout/auth-layout.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { LoginComponent } from './components/auth/login/login.component';
import { NewPasswordComponent } from './components/auth/new-password/new-password.component';
import { SignUpComponent } from './components/auth/sign-up/sign-up.component';
import { LayoutComponent } from './components/common/layout/layout.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { InstallationComponent } from './components/installation/installation.component';
import { AUTH_GUARD } from './guards/auth/auth.guard';
import { LOGGED_IN_GUARD } from './guards/logged-in/logged-in.guard';
import { OOB_CODE_GUARD } from './guards/oob-code/oob-code.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [LOGGED_IN_GUARD],
    children: [
      {
        title: 'Sign up',
        path: 'sign-up',
        component: SignUpComponent,
        canActivate: [OOB_CODE_GUARD],
      },
      {
        title: 'New password',
        path: 'new-password',
        component: NewPasswordComponent,
        canActivate: [OOB_CODE_GUARD],
      },
      {
        title: 'Login',
        path: 'login',
        component: LoginComponent,
      },
      {
        title: 'Forgot password',
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AUTH_GUARD],
    children: [
      {
        title: 'Dashboard',
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        title: 'Installation',
        path: 'installation',
        component: InstallationComponent,
      },
      {
        title: 'Account',
        path: 'account',
        component: AccountComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];
