import { Component } from '@angular/core';
import { Auth, reauthenticateWithCredential, updateEmail, updatePassword, updateProfile } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AbstractControl, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import { Subscription } from 'rxjs';

import {
  LOWERCASE_REGEX,
  NUMBER_REGEX,
  PASSWORD_REQUIREMENTS,
  SPECIAL_CHAR_REGEX,
  UPPERCASE_REGEX,
} from '../../constants/password-requirements';
import { ICustomer } from '../../interfaces/customer.interface';
import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { FireAuthService } from '../../services/fire-auth/fire-auth.service';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { regexValidator } from '../../validators/regex.validator';
import { SecondaryButtonComponent } from '../common/buttons/secondary-button/secondary-button.component';
import { PasswordRequirementsComponent } from '../common/password-requirements/password-requirements.component';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    SharedModule,
    MaterialModule,
    SecondaryButtonComponent,
    AngularFireAuthModule,
    PasswordRequirementsComponent,
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
})
export class AccountComponent {
  public editAccountForm: UntypedFormGroup;
  public editPasswordForm: UntypedFormGroup;
  public customer: ICustomer;
  public customerSub$: Subscription;
  public isNewPasswordVisible = false;
  public isCurrentPasswordVisible = false;
  public requirements = structuredClone(PASSWORD_REQUIREMENTS);

  public get name(): AbstractControl {
    return this.editAccountForm.get('name');
  }

  public get organization(): AbstractControl {
    return this.editAccountForm.get('organization');
  }

  public get email(): AbstractControl {
    return this.editAccountForm.get('email');
  }

  public get newPassword(): AbstractControl {
    return this.editPasswordForm.get('newPassword');
  }

  public get currentPassword(): AbstractControl {
    return this.editPasswordForm.get('currentPassword');
  }

  constructor(
    private fb: FormBuilder,
    private auth: Auth,
    private firestoreService: FirestoreService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private fireAuthService: FireAuthService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.setCustomer();
    this.createEditPasswordForm();
  }

  public ngOnDestroy(): void {
    if (this.customerSub$) this.customerSub$.unsubscribe();
  }

  public async setCustomer(): Promise<void> {
    this.customerSub$ = this.firestoreService
      .getDocument<ICustomer>(`customers/${this.auth.currentUser.uid}`)
      .subscribe((customer) => {
        this.customer = customer;
        this.createEditAccountForm();
      });
  }

  public createEditAccountForm(): void {
    this.editAccountForm = this.fb.group({
      name: [this.auth.currentUser.displayName, Validators.required],
      organization: [this.customer.organization, Validators.required],
      email: [this.auth.currentUser.email, [Validators.required, Validators.email]],
    });
  }

  public createEditPasswordForm(): void {
    this.editPasswordForm = this.fb.group({
      newPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(12),
          regexValidator(new RegExp(UPPERCASE_REGEX), { upperCase: true }),
          regexValidator(new RegExp(LOWERCASE_REGEX), { lowerCase: true }),
          regexValidator(new RegExp(NUMBER_REGEX), { number: true }),
          regexValidator(new RegExp(SPECIAL_CHAR_REGEX), { specialChar: true }),
        ],
      ],
      currentPassword: ['', Validators.required],
    });
  }

  public async saveAccountChanges(): Promise<void> {
    const promises: Promise<void>[] = [];

    const updateCustomerDto = {
      ...(this.organization.value !== this.customer.organization ? { organization: this.organization.value } : null),
      ...(this.email.value !== this.customer.email ? { email: this.email.value } : null),
    };

    if (Object.keys(updateCustomerDto).length > 0) {
      promises.push(this.firestoreService.updateDocument(`customers/${this.auth.currentUser.uid}`, updateCustomerDto));
    }

    if (this.name.value !== this.auth.currentUser.displayName) {
      promises.push(this.fireAuthService.updateProfile(this.auth, { displayName: this.name.value }));
    }

    if (updateCustomerDto.email) {
      promises.push(this.fireAuthService.updateEmail(this.auth, this.email.value));
    }

    await Promise.all(promises)
      .then(() => {
        const successMessage = this.translate.instant('common.changesSaved');
        this.snackBarService.openSuccessSnackBar(successMessage);
      })
      .catch(() => {
        const errorMessage = this.translate.instant('account.failedUpdateInfo');
        this.snackBarService.openFailedSnackBar(errorMessage);
      });
  }

  public async saveNewPassword(): Promise<void> {
    const credentials = firebase.auth.EmailAuthProvider.credential(
      this.auth.currentUser.email,
      this.currentPassword.value,
    );

    let failed = false;

    await reauthenticateWithCredential(this.auth.currentUser, credentials).catch(() => {
      const errorMessage = this.translate.instant('account.failedUpdatePassword');
      this.snackBarService.openFailedSnackBar(errorMessage);
      this.editPasswordForm.reset();
      failed = true;
    });

    if (failed) return;

    await updatePassword(this.auth.currentUser, this.newPassword.value)
      .then(() => {
        const successMessage = this.translate.instant('common.changesSaved');
        this.snackBarService.openSuccessSnackBar(successMessage);
      })
      .catch(() => {
        const errorMessage = this.translate.instant('account.failedUpdatePassword');
        this.snackBarService.openFailedSnackBar(errorMessage);
      })
      .finally(() => {
        this.editPasswordForm.reset();
      });
  }

  public updateRequirements(event: Event): void {
    this.requirements.minLength = event.target['value'].length >= 12;
    this.requirements.specialChar = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(event.target['value']);
    this.requirements.upperCase = /[A-Z]/.test(event.target['value']);
    this.requirements.lowerCase = /[a-z]/.test(event.target['value']);
    this.requirements.number = /[0-9]/.test(event.target['value']);
  }
}
