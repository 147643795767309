import { Component } from '@angular/core';
import { Auth, UserCredential } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { Bubble } from '../../../enums/bubble.enum';
import { ICustomer } from '../../../interfaces/customer.interface';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { FireAuthService } from '../../../services/fire-auth/fire-auth.service';
import { FirestoreService } from '../../../services/firestore/firestore.service';
import { BubbleComponent } from '../../common/bubble/bubble.component';
import { PrimaryButtonComponent } from '../../common/buttons/primary-button/primary-button.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    AngularFireAuthModule,
    MaterialModule,
    PrimaryButtonComponent,
    BubbleComponent,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  public loginForm: FormGroup;
  public isPasswordVisible = false;
  public hasFailedLogin = false;
  public completedSignUp = false;
  public Bubble = Bubble;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: Auth,
    private route: ActivatedRoute,
    private firestoreService: FirestoreService,
    private fireAuthService: FireAuthService,
  ) {}

  public ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    this.setCompletedSignUp();
  }

  public setCompletedSignUp(): void {
    const completedSignUp = this.route.snapshot.queryParams['completedSignUp'];

    if (completedSignUp === 'true') {
      this.completedSignUp = true;
    }
  }

  public async login(): Promise<void> {
    const auth: UserCredential = await this.fireAuthService
      .signInWithEmailAndPassword(this.auth, this.loginForm.get('email').value, this.loginForm.get('password').value)
      .catch(() => {
        this.hasFailedLogin = true;
        return undefined;
      });

    if (!auth) return;

    try {
      const customer = await firstValueFrom(this.firestoreService.getDocument<ICustomer>(`customers/${auth.user.uid}`));

      if (customer.isFirstLogin) {
        await this.firestoreService.updateDocument(`customers/${auth.user.uid}`, { isFirstLogin: false });
        this.router.navigate(['/installation'], { queryParams: { isFirstLogin: true } });
      } else {
        this.router.navigate(['/dashboard']);
      }
    } catch (error) {
      this.router.navigate(['/dashboard']);
    }
  }
}
