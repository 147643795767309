import { Injectable } from '@angular/core';
import {
  Auth,
  confirmPasswordReset,
  signInWithEmailAndPassword,
  updateEmail,
  updateProfile,
  UserCredential,
} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class FireAuthService {
  public async updateProfile(auth: Auth, data: object): Promise<void> {
    return updateProfile(auth.currentUser, data);
  }

  public async updateEmail(auth: Auth, email: string): Promise<void> {
    return updateEmail(auth.currentUser, email);
  }

  public async signInWithEmailAndPassword(auth: Auth, email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, email, password);
  }

  public async confirmPasswordReset(auth: Auth, oobCode: string, password: string): Promise<void> {
    return confirmPasswordReset(auth, oobCode, password);
  }
}
