import { Component, Input } from '@angular/core';
import { NgxFloatUiModule, NgxFloatUiPlacements } from 'ngx-float-ui';

import { SharedModule } from '../../../modules/shared.module';

@Component({
  selector: 'app-tooltip',
  standalone: true,
  imports: [SharedModule, NgxFloatUiModule],
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() label: string;
  @Input() placement: NgxFloatUiPlacements = NgxFloatUiPlacements.TOP;
}
