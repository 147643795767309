export const env = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAT17V-unPgTCte_EjAc1Zao8ihJfnS6_A',
    authDomain: 'my-deeploy-ute-f4522.firebaseapp.com',
    projectId: 'my-deeploy-ute-f4522',
    storageBucket: 'my-deeploy-ute-f4522.appspot.com',
    messagingSenderId: '477262997585',
    appId: '1:477262997585:web:95a5e06c5ea0a0ad443070',
  },
};
