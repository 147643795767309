import { IPasswordRequirements } from '../interfaces/password-requirements.interface';

export const SPECIAL_CHAR_REGEX = /^(?=.*[!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?~])/;
export const UPPERCASE_REGEX = /[A-Z]/;
export const LOWERCASE_REGEX = /[a-z]/;
export const NUMBER_REGEX = /[0-9]/;

export const PASSWORD_REQUIREMENTS: IPasswordRequirements = {
  minLength: false,
  specialChar: false,
  upperCase: false,
  lowerCase: false,
  number: false,
};
