import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../../../../modules/material.module';
import { SharedModule } from '../../../../modules/shared.module';

@Component({
  selector: 'app-popover-item',
  standalone: true,
  imports: [SharedModule, RouterModule, MaterialModule],
  templateUrl: './popover-item.component.html',
  styleUrls: ['./popover-item.component.scss'],
})
export class PopoverItemComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() navRouterLink: string;
  @Input() active: boolean;
}
