import { inject } from '@angular/core';
import { Auth, verifyPasswordResetCode } from '@angular/fire/auth';
import { CanActivateFn, Router } from '@angular/router';

export const OOB_CODE_GUARD: CanActivateFn = async (route, state) => {
  const auth = inject(Auth);
  const router = inject(Router);

  const oobCode = route.queryParams['oobCode'];

  if (!oobCode) return router.navigate(['/login']);

  return verifyPasswordResetCode(auth, oobCode)
    .then(() => true)
    .catch(() => router.navigate(['/login']));
};
