<div
  [routerLink]="navRouterLink"
  [ngClass]="{ 'toolbar-item--active': active }"
  class="toolbar-item"
>
  <p class="toolbar-item__label">{{ label | translate }}</p>

  @if (icon) {
  <mat-icon fontSet="material-icons-outlined">{{ icon }}</mat-icon>
  }
</div>
