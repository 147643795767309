<form [formGroup]="emailForm" class="forgot-password">
  @if (!isLinkSent) {
  <h1 translate>forgotPassword.forgotYourPassword</h1>

  <p translate>forgotPassword.enterTheEmail</p>

  @if (!isCreatingLink) {
  <mat-form-field appearance="outline" class="forgot-password__email">
    <input
      matInput
      [autofocus]="true"
      [placeholder]="'common.email' | translate"
      formControlName="email"
      type="text"
      autocomplete="email"
    />
  </mat-form-field>
  } @else {
  <app-spinner [waitForMs]="0" margin="small"></app-spinner>
  }

  <a href="/login" translate>forgotPassword.backToLogin</a>

  <app-primary-button
    (clicked)="sendPasswordResetEmail()"
    [disabled]="emailForm.invalid || isCreatingLink"
    [text]="'forgotPassword.sendEmail' | translate"
    width="full"
    class="forgot-password__send-button"
  ></app-primary-button>
  } @else {
  <h1 translate>forgotPassword.resetLinkSent</h1>

  <p>{{ "forgotPassword.linkSent" | translate : { email: email.value } }}</p>

  <app-primary-button
    (clicked)="navigateToLogin()"
    [text]="'forgotPassword.backToLogin' | translate"
    width="full"
    class="forgot-password__login-button"
  ></app-primary-button>

  <p>
    {{ "forgotPassword.didntReceive" | translate }}
    <a (click)="resetState()" translate>forgotPassword.tryAgain</a>
  </p>
  }
</form>
