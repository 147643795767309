export enum AvatarColor {
  BROWN = '#D08561',
  GRAPEFRUIT = '#E99478',
  RED = '#EC8B80',
  ORANGE = '#ECC180',
  YELLOW = '#E9CD78',
  GREEN = '#87CD9B',
  DARK_GREEN = '#93B69D',
  AQUA = '#9DD9D5',
  BLUE = '#80BFEC',
  VIOLET = '#7879E9',
  PURPLE = '#CAA1EA',
  PINK = '#CD87A0',
  GRAY = '#F0F0F0',
}
