import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MaterialModule } from '../../../../modules/material.module';
import { SharedModule } from '../../../../modules/shared.module';

@Component({
  selector: 'app-primary-button',
  standalone: true,
  imports: [SharedModule, MaterialModule],
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent {
  @Input() text: string;
  @Input() disabled = false;
  @Input() icon: 'save' | 'delete' | 'add' | 'archive' | 'arrow_back_ios' | 'arrow_forward_ios' | 'keyboard_arrow_down';
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() type: 'button' | 'submit' = 'button';
  @Input() color: 'primary' | 'error' = 'primary';
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() width: 'wrap' | 'full' = 'wrap';
  @Input() analyticsId?: string;

  @Output() clicked = new EventEmitter();

  public click(event: MouseEvent): void {
    this.clicked.emit();
    event.stopPropagation();
  }
}
