import { inject } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

export const AUTH_GUARD: CanActivateFn = async (route, state) => {
  const auth = inject(Auth);
  const router = inject(Router);

  return new Promise<boolean | UrlTree>((resolve) => {
    onAuthStateChanged(
      auth,
      (authState) => {
        if (!authState) return resolve(router.createUrlTree(['/login']));

        if (!authState['auth']?.currentUser) {
          return resolve(router.createUrlTree(['/login']));
        } else {
          return resolve(true);
        }
      },
      (error) => {
        console.error(error);
        return resolve(router.createUrlTree(['/login']));
      },
    );
  });
};
