import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { ISnackbarData } from '../../../interfaces/snack-bar-data.interface';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';

@Component({
  selector: 'app-snackbar',
  standalone: true,
  imports: [SharedModule, MaterialModule],
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarData) {}

  public dismiss(): void {
    this.data.preClose();
  }
}
