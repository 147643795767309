import { Component } from '@angular/core';
import { Auth, User } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { distinctUntilChanged, filter, Subscription } from 'rxjs';

import { Page } from '../../../enums/page.enum';
import { ICustomer } from '../../../interfaces/customer.interface';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { FunctionsService } from '../../../services/functions/functions.service';
import { AvatarComponent } from '../avatar/avatar.component';
import { PopoverItemComponent } from '../popover/popover-item/popover-item.component';
import { PopoverComponent } from '../popover/popover.component';
import { ToolbarItemComponent } from './toolbar-item/toolbar-item.component';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [
    SharedModule,
    ToolbarItemComponent,
    AvatarComponent,
    AngularFireAuthModule,
    AngularFirestoreModule,
    PopoverComponent,
    PopoverItemComponent,
    RouterModule,
    MaterialModule,
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent {
  public user: User;
  public customer: ICustomer;
  public currentPage: Page;
  public routerSubscription$: Subscription;
  public stripeUrl: string;
  public isAvatarPopoverOpen = false;

  public Page = Page;

  constructor(
    private auth: Auth,
    private router: Router,
    private functionsService: FunctionsService,
  ) {}

  public ngOnInit(): void {
    this.user = this.auth.currentUser;
    this.subscribeToRouteEvents();
  }

  public ngOnDestroy(): void {
    if (this.routerSubscription$) this.routerSubscription$.unsubscribe();
  }

  public async createBillingPortal(): Promise<void> {
    const data: { url: string } = await this.functionsService.call('stripe-createBillingPortal', {
      returnUrl: window.location.href,
    });

    window.location.assign(data.url);
  }

  public async logOut(): Promise<void> {
    await this.auth.signOut();
    this.router.navigate(['/login']);
  }

  public navigateToAccount(): void {
    this.router.navigate(['/account']);
    this.isAvatarPopoverOpen = false;
  }

  public subscribeToRouteEvents(): void {
    this.setCurrentPage(this.router.url);

    this.routerSubscription$ = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
      )
      .subscribe((navigationEnd: NavigationEnd) => {
        this.setCurrentPage(navigationEnd.url);
      });
  }

  public setCurrentPage(url: string): void {
    for (const navPage of Object.values(Page)) {
      if (url.indexOf(navPage) > 0) {
        this.currentPage = navPage;
      }
    }
  }
}
