import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';

@Pipe({
  name: 'timestamp',
  standalone: true,
})
export class TimestampPipe implements PipeTransform {
  public transform(timestamp: Timestamp, format?: string): string {
    const DEFAULT_DATE_FORMAT = 'dd-MM-yyy HH:mm zzz';
    format = format ?? DEFAULT_DATE_FORMAT;

    const date = timestamp.toDate();
    return formatDate(
      date,
      format,
      Intl.DateTimeFormat().resolvedOptions().locale,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
  }
}
