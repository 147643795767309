<div
  (mouseenter)="copyTooltip.show()"
  (mouseleave)="copyTooltip.hide()"
  (click)="copyValue()"
  [floatUi]="copyTooltip"
  [disabled]="true"
  [placement]="placement"
>
  <ng-content></ng-content>
</div>

<div class="copy-tooltip">
  <float-ui-content #copyTooltip>
    {{ label | translate }}
  </float-ui-content>
</div>
