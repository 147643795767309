import { Component, Input } from '@angular/core';

import { SharedModule } from '../../../modules/shared.module';

@Component({
  selector: 'app-installation-card',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './installation-card.component.html',
  styleUrl: './installation-card.component.scss',
})
export class InstallationCardComponent {
  @Input() title: string;
}
