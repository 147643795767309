import { Component, Input } from '@angular/core';

import { SharedModule } from '../../../modules/shared.module';

@Component({
  selector: 'app-entity-card',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './entity-card.component.html',
  styleUrl: './entity-card.component.scss',
})
export class EntityCardComponent {
  @Input() count: number;
  @Input() label: string;
}
