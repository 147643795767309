<button
  mat-flat-button
  (click)="click($event)"
  [disabled]="disabled"
  [type]="type"
  [attr.data-analytics-id]="analyticsId ? analyticsId : null"
  class="primary-button primary-button__{{ size }} {{ color }} {{ width }}"
>
  @if (icon) {
  <mat-icon
    class="{{ text ? iconPosition : '' }}"
    fontSet="material-icons-outlined"
    >{{ icon }}</mat-icon
  >
  }
  <ng-content select="[icon-left]"></ng-content>
  @if (text) {
  <p>{{ text }}</p>
  }
  <ng-content select="[icon-right]"></ng-content>
</button>
