<div class="dashboard-empty">
  <img src="/public/svg/dashboard.svg" alt="Welcome to Dashboard" />

  <h1 translate>dashboard.welcome</h1>
  <p translate>dashboard.completeInstallation</p>

  <app-secondary-button
    (clicked)="navigateToInstallation()"
    [text]="'dashboard.goToInstallation' | translate"
  ></app-secondary-button>
</div>
